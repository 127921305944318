import { View } from '@react-pdf/renderer';
import RichText from '@rsa-digital/evo-shared-components/components/pdf/components/RichText';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { Heading2 } from '../typography';

export type SummaryOfNeedsSectionProps = {
  theme: DefaultTheme;
  textBeforeStatement: string | undefined;
  textAfterStatement: string | undefined;
  excessStatements: string[] | undefined;
};

const SummaryOfNeedsSection: React.FC<SummaryOfNeedsSectionProps> = ({
  excessStatements,
  textBeforeStatement,
  textAfterStatement,
  theme,
}) => (
  <>
    <Section>
      <Heading2>Summary of your needs</Heading2>
      <SimpleDivider theme={theme} />
      <View style={{ marginTop: 24 }} />
      {textBeforeStatement && <RichText theme={theme} html={textBeforeStatement} />}
      {excessStatements &&
        excessStatements.map((statement) => (
          <RichText key={statement} theme={theme} html={statement} />
        ))}
      {textAfterStatement && <RichText theme={theme} html={textAfterStatement} />}
    </Section>
  </>
);

export default SummaryOfNeedsSection;
