import { Polygon, Svg, Text } from '@react-pdf/renderer';
import React from 'react';

// medium heading
export const Heading1: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Text
    style={{
      fontSize: 40,
      fontWeight: 'bold',
      lineHeight: 1.5,
    }}>
    {children}
  </Text>
);

// small heading
export const Heading2: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Text
    style={{
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.5,
    }}>
    {children}
  </Text>
);

export const Included: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Svg width="16" height="16" viewBox="0 0 24 24" style={{ marginRight: 5 }}>
    <Polygon
      points="15.3556864 2 15.996939 2.71340895 5.85190665 14 0.996939047 8.59873249 1.63819174 7.88532354 5.85190665 12.5727854"
      stroke="#005640"
      strokeWidth="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    {children}
  </Svg>
);

export const NotIncluded: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Svg width="16" height="16" viewBox="0 0 24 24" style={{ marginRight: 5 }}>
    <Polygon
      points="1.42426407 0.575735931 15.4242641 14.5757359 14.5757359 15.4242641 0.575735931 1.42426407"
      stroke="#000000"
      strokeWidth="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Polygon
      points="14.5757359 0.575735931 15.4242641 1.42426407 1.42426407 15.4242641 0.575735931 14.5757359"
      stroke="#000000"
      strokeWidth="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    {children}
  </Svg>
);
