import { View } from '@react-pdf/renderer';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { ProductFeature } from 'components/QuoteSummary/ProductFeaturesTable/content';
import { Product } from 'helpers/productHelpers';
import ProductFeaturesTablePdf from './ProductFeaturesTablePdf';
import { Heading2 } from '../typography';

export type ProductFeatureSectionProps = {
  product: Product;
  replaceProductPlaceholders: (csPlainTextString: string) => string;
  theme: DefaultTheme;
  includedFeatures: string;
  notIncludedFeatures: string;
  includedFeaturesForProduct: ProductFeature[];
  notIncludedFeaturesForProduct: ProductFeature[];
};

const ProductFeatureSection: React.FC<ProductFeatureSectionProps> = ({
  product,
  replaceProductPlaceholders,
  theme,
  includedFeaturesForProduct,
  notIncludedFeaturesForProduct,
  includedFeatures,
  notIncludedFeatures,
}) => (
  <>
    <Section>
      <Heading2> {replaceProductPlaceholders(includedFeatures)}</Heading2>
      <SimpleDivider theme={theme} />
      <ProductFeaturesTablePdf
        theme={theme}
        product={product}
        replaceProductPlaceholders={replaceProductPlaceholders}
        features={includedFeaturesForProduct}
      />
      <View>
        {notIncludedFeaturesForProduct.length > 0 && (
          <>
            <Heading2> {replaceProductPlaceholders(notIncludedFeatures)}</Heading2>
            <SimpleDivider theme={theme} />
            <ProductFeaturesTablePdf
              theme={theme}
              product={product}
              replaceProductPlaceholders={replaceProductPlaceholders}
              features={notIncludedFeaturesForProduct}
            />
          </>
        )}
      </View>
    </Section>
  </>
);

export default ProductFeatureSection;
